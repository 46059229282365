<template>
  <v-container fluid>
    <v-tabs>
      <v-tab>County</v-tab>
      <v-tab-item>
        <County></County>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>
<script>
import County from '@components/admin/LuCountyGrid'
export default {
  page: {
    title: 'Public Funds Admin',
  },
  components: {
    County,
  },
  data() {
    return {}
  },
  created() {},
  computed: {},
  methods: {},
  watch: {},
}
</script>
<style scoped></style>
